import { MENU_DIC, CLIENT_TYPE_EXTERNAL_BUSINESS,  CLIENT_TYPE_EXTERNAL_ADMIN} from '@/utils/constant'
import { createOrUpdateAdmin, createOrUpdateBusiness, createOrUpdateFactory } from '@/api/role'
import { checkPermission } from '@/utils'
const externalAdmin = MENU_DIC[CLIENT_TYPE_EXTERNAL_ADMIN]
const externalBusiness = MENU_DIC[CLIENT_TYPE_EXTERNAL_BUSINESS]

export const list = [
  {
    label: externalAdmin.label,
    value: externalAdmin.value,
    dicType: 'adminMenuList',
    url: '/externaladmin/authService/role/adminRoleList',
    save: createOrUpdateAdmin,
    permission: checkPermission('externaladmin:system:roles:adminRole'),
    childPermission: {
      search: checkPermission(['externaladmin:system:roles:adminRole:search']),
      add: checkPermission(['externaladmin:system:roles:adminRole:add']),
      edit: checkPermission(['externaladmin:system:roles:adminRole:edit']),
      assign: checkPermission(['externaladmin:system:roles:adminRole:assign']),
      delete: checkPermission(['externaladmin:system:roles:adminRole:delete'])
    }
  },
  {
    label: externalBusiness.label,
    value: externalBusiness.value,
    dicType: 'businessMenuList',
    url: '/externaladmin/authService/role/businessRoleList',
    save: createOrUpdateBusiness,
    permission: checkPermission(['externaladmin:system:roles:businessRole']),
    childPermission: {
      search: checkPermission(['externaladmin:system:roles:businessRole:search']),
      add: checkPermission(['externaladmin:system:roles:businessRole:add']),
      edit: checkPermission(['externaladmin:system:roles:businessRole:edit']),
      assign: checkPermission(['externaladmin:system:roles:businessRole:assign']),
      delete: checkPermission(['externaladmin:system:roles:businessRole:delete'])
    }
  }
]
