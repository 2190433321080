<template>
  <div class="app-container">
    <listStateGroup v-model="clientType" :list="list" class="mb20" @change="handleListStateChange"></listStateGroup>
    <eHeader
      v-if="childPermission.add || childPermission.search"
      :query="query"
      :sup_this="sup_this"
      :permission="childPermission"
      :clientType="clientType"
    />
    <el-row :gutter="28" class="system-role-row">
      <el-col :span="childPermission.assign ? span : 24">
        <!--分页组件-->
        <PaginationBar
          comstomClass="pagination"
          :background="false"
          :page="page"
          :size="size"
          :total="total"
          style="margin-bottom: 8px"
          layout="total, prev, pager, next, sizes"
          @refreshTableEventFun="refreshTableEventFun"
        />
        <!--表格渲染-->
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="data"
          size="small"
          border
          style="width: 100%"
          @current-change="handleCurrentChange"
        >
          <el-table-column label="序号" width="60" align="center">
            <template slot-scope="scope">
              <div>{{ scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="roleName" label="名称" />
          <el-table-column prop="createUserName" label="创建人" />
          <el-table-column v-if="table_show" prop="description" label="描述" />
          <el-table-column
            v-if="childPermission.edit || childPermission.delete"
            label="操作"
            align="center"
            width="150px"
          >
            <template slot-scope="scope">
              <edit v-if="childPermission.edit" :data="scope.row" :sup_this="sup_this" />
              <Popover v-if="childPermission.delete" @sureHandler="subDelete(scope.row.id, $event)">
                <template #tip>
                  <p>确定删除本条数据吗？</p>
                </template>
                <template #reference="{ scope: loading }">
                  <el-button type="text" danger size="mini" :loading="loading" @click="handleDel"> 删除</el-button>
                </template>
              </Popover>
            </template>
          </el-table-column>
        </el-table>
        <!--分页组件-->
        <PaginationBar
          comstomClass="pagination"
          :background="false"
          :page="page"
          :size="size"
          :total="total"
          style="margin-top: 8px"
          layout="total, prev, pager, next, sizes"
          @refreshTableEventFun="refreshTableEventFun"
        />
      </el-col>
      <el-col v-show="childPermission.assign" :span="8">
        <el-card v-if="show" v-loading="menuLoading[clientType]" class="role-page-box-card">
          <div slot="header" class="clearfix">
            <span>菜单分配-{{ row_data.roleName }}</span>
            <el-button
              :loading="loading"
              icon="el-icon-check"
              size="mini"
              type="success"
              style="float: right; padding: 4px 8px"
              @click="toSave"
              >保存
            </el-button>
          </div>
          <el-tree
            ref="menu"
            :data="menuList.menuList"
            :props="{ label: 'menuName' }"
            :check-strictly="true"
            :default-checked-keys="menuIds"
            show-checkbox
            accordion
            node-key="id"
            highlight-current
            @check="handleCheck"
          >
          </el-tree>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { retrieve, del } from '@/api/role'
import initData from '@/mixins/initData'
import eHeader from './module/header'
import edit from './module/edit'
import { deepClone } from '@/components/avue/utils/util'
import listStateGroup from '@/views/components/listStateGroup'
import { list } from './const'
import { getRemark, shakingChildren } from '@/utils'
import { CLIENT_TYPE_EXTERNAL_BUSINESS, CLIENT_TYPE_EXTERNAL_ADMIN } from '@/utils/constant'
import { uniq } from 'lodash'

export default {
  mixins: [initData],
  components: {
    eHeader,
    edit,
    listStateGroup
  },
  data() {
    return {
      query: {
        // createUserName: this.$store.getters.name
      },
      // 客户端类型 16对外分销业务端 17对外分销后台
      clientType: CLIENT_TYPE_EXTERNAL_BUSINESS,
      method: 'post',
      row_data: null,
      span: 24,
      show: false,
      table_show: true,
      loading: false,
      sup_this: this,
      menus: {},
      menuLoading: {},
      menuIds: [],
      oMenuIds: [],
      defaultProps: Object.freeze({
        children: 'children',
        label: 'label'
      })
    }
  },
  computed: {
    list() {
      return list.filter((item) => item.permission)
    },
    curItem({ list, clientType }) {
      return list.find(({ value }) => value === clientType)
    },
    childPermission({ curItem }) {
      return curItem.childPermission
    },
    url({ curItem }) {
      return curItem.url
    },
    save({ curItem, menuList: { hideMenuIds } }) {
      return (...args) => {
        if (!args[0].id) {
          args[0].createMenuIdList = Array.isArray(hideMenuIds[0]) ? hideMenuIds[0] : []
        }
        return curItem.save(...args)
      }
    },
    menuList({ menus, clientType }) {
      let allMenu = menus[clientType] || []
      let menuList = this.getShowMenus(allMenu)
      let showMenusObj = this.getShowMenusObj(menuList)
      // console.log(menuList, showMenusObj)
      return {
        menuList,
        showMenusObj,
        hideMenuIds: this.getHideMenuOfParent(allMenu)
      }
    }
  },
  watch: {
    list: {
      handler(n) {
        if (n.length) {
          this.clientType = n[0].value
        }
      },
      immediate: true,
      deep: true
    },
    clientType: {
      handler() {
        let {
          curItem: { value, dicType },
          menus
        } = this
        if (menus[value] === undefined) {
          this.$set(this.menuLoading, value, true)
          this.$store
            .dispatch('GetDic', dicType)
            .then((dicData) => {
              this.$set(menus, value, this.handleData(dicData))
            })
            .finally(() => (this.menuLoading[value] = false))
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getRemark,
    handleData(data) {
      return data?.map((row) => {
        row.remark = this.getRemark(row)
        this.handleData(row.children)
        return row
      })
    },
    // beforeInit() {
    //   const sort = "id";
    //   const query = this.query;
    //   const value = query.value;
    //   this.params = { page: this.page, size: this.size, ordering: sort };
    //   if (value) {
    //     this.params["search"] = value;
    //   }
    //   return true;
    // },
    handleListStateChange() {
      this.page = 1
      this.searchChange()
      this.span = 24
      this.show = false
    },

    handleCurrentChange(val) {
      //存在val === null的情况
      if (!val || this.isDelBtnClick) {
        this.isDelBtnClick = false
        this.$refs.table.setCurrentRow()
        return
      }
      if (this.show) {
        // 清空选中状态
        this.$refs.menu.setCheckedKeys([])
      } else {
        this.show = true
      }
      this.span = 16
      // this.table_show = false;
      this.row_data = val
      if (val) {
        // this.menuIds = this.row_data.menus;
        let menuList = val.menuIdList || []
        this.oMenuIds = menuList
        this.$nextTick(() => {
          this.menuIds = menuList
          // console.log(this.oMenuIds, this.menuIds)
        })
      }
    },
    handleDel() {
      this.isDelBtnClick = true
      setTimeout(() => {
        this.isDelBtnClick = false
      }, 500)
    },
    async subDelete(id, popover) {
      popover.loading = true
      let [, res] = await awaitWrap(del([id]))
      if ($SUC(res)) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '删除成功!',
          duration: 2500
        })
        await Promise.all([
          this.init(),
          this.$store.dispatch('RefreshDic', 'businessRoleList'),
          this.$store.dispatch('RefreshDic', 'adminRoleList')
        ])
      }
      popover.loading = false
    },
    async toSave() {
      let {
        menuList: { hideMenuIds },
        $refs: { menu }
      } = this
      this.loading = true
      let menuIds = menu.getCheckedKeys().concat(Array.isArray(hideMenuIds[0]) ? hideMenuIds[0] : [])
      // 执行两次，把 childIds 里的菜单子隐藏菜单也获取到
      for (let i = 0; i < 2; i++) {
        menuIds.forEach((id) => {
          let children = hideMenuIds[id]
          if (children) {
            menuIds = menuIds.concat(children)
          }
        })
      }
      menuIds = uniq(menuIds)

      let oMenuIds = this.oMenuIds
      let form = {
        // 新增菜单ID
        createMenuIdList: menuIds.filter((id) => !oMenuIds.includes(id)),
        // 删除菜单ID
        delMenuIdList: oMenuIds.filter((id) => !menuIds.includes(id))
      }
      // console.log(menuIds, oMenuIds, form)

      let isSuccess = false
      if (form.createMenuIdList.length || form.delMenuIdList.length) {
        let [, res] = await awaitWrap(
          this.curItem.save({
            ...form,
            id: this.row_data.id
          })
        )
        if ($SUC(res)) {
          isSuccess = true
          this.init()
        }
      } else {
        isSuccess = true
      }
      if (isSuccess) {
        this.oMenuIds = menuIds
        this.$message({
          showClose: true,
          type: 'success',
          message: '保存成功!',
          duration: 2500
        })
      }
      this.loading = false
    },
    update(id) {
      // 刷新选中行数据
      retrieve(id).then((res) => {
        res = res.detail
        for (let i = 0; i < this.data.length; i++) {
          if (res.id === this.data[i].id) {
            //使用splice方法触发dom更新
            this.data.splice(i, 1, res)
            // this.data[i] = res
            break
          }
        }
      })
    },

    handleCheck({ id }, { checkedKeys }) {
      let {
        $refs: { menu },
        menuList: { showMenusObj }
      } = this
      let childKeys = showMenusObj[id]
      if (menu && childKeys) {
        let oCheckedKeys = menu.getCheckedKeys()
        let nCheckedKeys = []

        if (checkedKeys.includes(id)) {
          // 选中
          nCheckedKeys = childKeys.concat(oCheckedKeys)
        } else {
          // 取消选中
          nCheckedKeys = oCheckedKeys.filter((key) => !childKeys.includes(key))
        }
        menu.setCheckedKeys(nCheckedKeys)
      }
    },

    getHideMenuOfParent(menuList) {
      let tempObj = {}
      if (Array.isArray(menuList)) {
        menuList.forEach((node) => {
          if (Array.isArray(node.childIds) && node.childIds.length) {
            if (!tempObj[node.id]) tempObj[node.id] = []
            tempObj[node.id] = tempObj[node.id].concat(node.childIds)
          }
          if (node.isShow === 0 || node.remark.includes('required')) {
            if (!tempObj[node.parentId]) tempObj[node.parentId] = []
            tempObj[node.parentId].push(node.id)
            if (Array.isArray(node.children)) {
              // console.log(this.getHideMenuOfParent(node.children))
              return (tempObj[node.parentId] = tempObj[node.parentId].concat(
                shakingChildren(node.children).map((item) => item.id)
              ))
            }
          }
          // console.log(node.children, this.getHideMenuOfParent(node.children))
          if (Array.isArray(node.children)) {
            let childObj = this.getHideMenuOfParent(node.children)
            for (const key in childObj) {
              if (tempObj[key]) {
                tempObj[key] = tempObj[key].concat(childObj[key])
              } else {
                tempObj[key] = childObj[key]
              }
            }
          }
        })
      }
      return tempObj
    },
    getShowMenus(tree) {
      return deepClone(tree || []).filter((node) => {
        node.disabled = node.disabled || node.isPermissionControl === 0
        if (node.isShow === 1 && !/required|hidden/.test(node.remark)) {
          node.children = this.getShowMenus(node.children)
          return true
        }
      })
    },
    getShowMenusObj(menuList) {
      let tempObj = {}
      if (Array.isArray(menuList)) {
        menuList.forEach((node) => {
          if (!tempObj[node.parentId]) tempObj[node.parentId] = []
          tempObj[node.parentId].push(node.id)
          if (Array.isArray(node.children)) {
            let childObj = this.getShowMenusObj(node.children)
            tempObj[node.parentId] = tempObj[node.parentId].concat(childObj[node.id] || [])
            Object.assign(tempObj, childObj)
          }
        })
      }
      return tempObj
    }
  }
}
</script>

<style lang="scss">
.pagination {
  margin-top: 10px;
  text-align: right;
}

.el-card__header {
  font-size: 14px;
}

.system-role-row {
  .el-col-16 {
    width: calc(100% - 400px);
  }

  .el-col-8 {
    width: 400px;
  }
}

.role-page-box-card {
  position: fixed;
  width: 372px;
  display: flex;
  flex-direction: column;
  height: 60vh;

  .el-card__body {
    flex: 1;
    overflow: auto;
  }
}
</style>
